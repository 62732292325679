var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account-block"},[_c('menu-breadcrumb',[_c('el-breadcrumb-item',[_vm._v("帳號管理")])],1),_c('div',{staticClass:"container"},[_c('table-title',[_c('p',[_vm._v(" 帳號列表 "),_c('span',{staticClass:"unit"},[_vm._v("數量："+_vm._s(_vm.total))])]),_c('el-button',{attrs:{"type":"success"},on:{"click":function($event){return _vm.$router.push({ name: 'CreateAccount' })}}},[_vm._v("新增帳號 ")])],1),_c('el-table',{attrs:{"data":_vm.account_data}},[_c('el-table-column',{attrs:{"prop":"username","label":"信箱","fixed":"left","width":"200","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-overflow"},[_vm._v(" "+_vm._s(row.username)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"暱稱","width":"160","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-overflow"},[_vm._v(" "+_vm._s(row.name)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"role","label":"角色","width":"160","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.handleRoleName(row.role))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"created_at","label":"創建時間","width":"200","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$formatTime(row["created_at"]))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"updated_at","label":"更新時間","width":"200","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$formatTime(row["updated_at"]))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"active","label":"狀態","fixed":"right","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.active)?_c('div',{staticStyle:{"color":"var(--success-color)"}},[_vm._v(" 啟用 ")]):_c('div',{staticStyle:{"color":"var(--danger-color)"}},[_vm._v("停用")])]}}])}),_c('el-table-column',{attrs:{"label":"編輯","fixed":"right","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"size":"mini","type":"warning","icon":"el-icon-edit"},on:{"click":function($event){return _vm.$router.push({
                name: 'EditAccount',
                params: { id: row['user_id'] },
              })}}},[_vm._v("編輯")]),_c('el-button',{attrs:{"size":"mini","type":"danger","icon":"el-icon-document-delete"},on:{"click":function($event){return _vm.handleDisable(row['user_id'])}}},[_vm._v("停用")])]}}])})],1),_c('table-pagination',{attrs:{"total":_vm.total,"limit":_vm.setup.limit,"page":_vm.setup.page},on:{"change-page":_vm.handleChangePage}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }