<template>
  <div class="account-block">
    <menu-breadcrumb>
      <el-breadcrumb-item>帳號管理</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <table-title>
        <p>
          帳號列表 <span class="unit">數量：{{ total }}</span>
        </p>

        <el-button
          type="success"
          @click="$router.push({ name: 'CreateAccount' })"
          >新增帳號
        </el-button>
      </table-title>

      <el-table :data="account_data">
        <el-table-column
          prop="username"
          label="信箱"
          fixed="left"
          width="200"
          sortable
        >
          <template v-slot="{ row }">
            <div class="text-overflow">
              {{ row.username }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="暱稱" width="160" sortable>
          <template v-slot="{ row }">
            <div class="text-overflow">
              {{ row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="role" label="角色" width="160" sortable>
          <template v-slot="{ row }">
            {{ handleRoleName(row.role) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="created_at"
          label="創建時間"
          width="200"
          sortable=""
        >
          <template v-slot="{ row }">
            {{ $formatTime(row["created_at"]) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="updated_at"
          label="更新時間"
          width="200"
          sortable
        >
          <template v-slot="{ row }">
            {{ $formatTime(row["updated_at"]) }}
          </template>
        </el-table-column>
        <el-table-column prop="active" label="狀態" fixed="right" sortable>
          <template v-slot="{ row }">
            <div v-if="row.active" style="color: var(--success-color)">
              啟用
            </div>
            <div v-else style="color: var(--danger-color)">停用</div>
          </template>
        </el-table-column>
        <el-table-column label="編輯" fixed="right" width="180">
          <template v-slot="{ row }">
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-edit"
              @click="
                $router.push({
                  name: 'EditAccount',
                  params: { id: row['user_id'] },
                })
              "
              >編輯</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-document-delete"
              @click="handleDisable(row['user_id'])"
              >停用</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <table-pagination
        :total="total"
        :limit="setup.limit"
        :page="setup.page"
        @change-page="handleChangePage"
      ></table-pagination>
    </div>
  </div>
</template>

<script>
import { getUsersPagination, updateUser, getRoles } from "@/api/user";

export default {
  name: "Account",
  data() {
    return {
      account_data: [],
      total: 0,
      setup: {
        page: 0,
        limit: 25,
      },
      roles: [],
    };
  },
  created() {
    if (this.$route.query.p) {
      this.setup.page = +this.$route.query.p;
    }

    this.handleGetUsers();
    this.handleGetRoles();
  },
  methods: {
    async handleGetUsers() {
      const { results, total } = await getUsersPagination(this.setup);
      this.account_data = results;
      this.total = total;
    },
    handleChangePage(page) {
      this.setup.page = page;

      this.handleGetUsers();
    },
    handleDisable(user_id) {
      this.$confirm("是否停用該角色", "警告", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await updateUser(user_id, { active: false });
          await this.handleGetUsers();

          this.$message({
            type: "success",
            message: "編輯成功",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "編輯取消",
          });
        });
    },
    async handleGetRoles() {
      this.roles = await getRoles();
    },
    handleRoleName(role) {
      return this.roles.find(({ value }) => value === role)?.label || "N/A";
    },
  },
};
</script>

<style lang="scss" scoped></style>
